.content_display{
    display: flex;
    flex-direction: column; 
    margin: 0 auto;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px; 
    padding: 10px;
    width: 1050px;
   height: auto;
   background-color: white;
   margin-top: 10px;
}
.info_section {
    display: flex;
    justify-content: space-between; /* Répartit l'espace entre les sections */
    width: 100%;
    margin-top: 20px;
    margin-left: 60px;
    font-size: 20px;
}

.personal_info {
    margin-right: 40px; /* Espacement entre les sections */
}

.info_row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.label {
    width: 200px;
    margin-right: 20px;  
}
.btn_location{
    text-align: right; /* Aligne le contenu à droite */
    margin-top: 10px; /* Espace au-dessus du bouton */
    margin-bottom: 10px;
    margin-right: 20px;
}

.facture_pay{
    text-align: right;
    margin-right: 230px;
    font-size: 20px;
}
.checkbox_informations{
    margin: 10px 0;
    text-align: right;
    margin-right: 230px;
    font-size:18px;
    display: flex;
    flex-direction: column;
    
}
.input_additionalInfo{
    margin: 10px 0;
    width: 600px;
    font-size:18px;
    float: right;
    margin-left: 700px; /* Aligne l'input avec les autres éléments */
    
}

.check_information{
    width: 10%;
}

.error_message{
    text-align: right;
    margin-right: 230px;
    color: red;
     font-style: italic;
}
label{
    display: inline;
}
@media only screen and (max-width: 480px) {
    
    .info_section {
        display: flex;
        flex-direction: column; /* Aligne les éléments en colonne */
      
        margin-left: 10px;
        font-size: 12px;
    }
    
    .personal_info {
        margin-right: 50px; /* Espacement entre les sections */
    }
    
    .label {
        width: 100px;
        margin-right: 0px  
    }
   
    .facture_pay{
        margin-right: 30px;
        font-size: 12px;
    } 
    .checkbox_informations{
        margin: 10px 0;    
        margin-right: 30px;
        font-size: 12px;
    }
    .input_additionalInfo{
        width: 250px;
        font-size:12px;
        margin-left: 30px;
    }
    .error_message{
        font-size: 12px;
        margin-right: 30px;
    
    }

}

@media only screen and (min-width: 320px) and (max-width: 355px) {
    .content_display{
        width: 290px;
    }

}
@media only screen and (min-width: 355px) and (max-width: 381px) {
    .content_display{
        width: 320px;
    }
    .input_additionalInfo{
        width: 290px;
    }
}
@media only screen and (min-width: 381px) and (max-width: 480px) {
    .content_display{
        max-width: 355px;
    }
    .input_additionalInfo{
        width: 350px;
     
    }
}
@media only screen and (min-width: 480px) and (max-width: 521px) {
    .content_display{
        max-width: 320px;
    }
}

@media only screen and (min-width: 521px) and (max-width: 768px) {
    .content_display{
        max-width: 500px;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .content_display{
        max-width: 700px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .info_section {
        display: flex;
        flex-direction: column; /* Aligne les éléments en colonne */  
        margin-left: 10px;
        font-size: 15px;
    }
    
    .personal_info {
        margin-right: 50px; /* Espacement entre les sections */
    }
    
    .label {
        width: 100px;
        margin-right: 0px  
    }
   
    .facture_pay{
        text-align: right;
        margin-right: 140px;
        font-size: 15px;
    }
    .checkbox_informations{
        margin: 10px 0;    
        margin-right: 140px;
        font-size: 15px;
    }
    .input_additionalInfo{
        width: 500px;
        font-size:15px;
        margin-left: 140px;
    }
    .error_message{
        font-size: 15px;
        margin-right: 140px;
    
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .info_section {
        margin-left: 10px;
        font-size: 18px;
    }
    
    .personal_info {
        margin-right: 50px; /* Espacement entre les sections */
    }
    
    .label {
        width: 100px;
        margin-right: 0px  
    }
   
    .facture_pay{
        text-align: right;
        margin-right: 140px;
        font-size: 18px;
    }
    .checkbox_informations{
        margin-right: 140px;
        font-size: 18px;
    }
    .input_additionalInfo{
        font-size: 18px;
        margin-left: 260px;
    }
    .error_message{
        font-size: 18px;
        margin-right: 140px;
    
    }
}