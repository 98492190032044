.soundCloud_band{
    margin: 0 auto;
    text-align: center;
    display: flex;
    margin-top: -40px;
    position: relative;
    z-index: 1000;
}
.video-container{
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%;
}
.youtube_band{
    width: 700px;
    height: 415px;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .youtube_band{
        height: 170px;
        width: auto;
    }
    .video-container{
        justify-content: left;
    }
}

@media only screen and (min-width: 420px) and (max-width: 550px) {
    .youtube_band{
        height: 200px;
        width: 350px;
    }
    .video-container{
        justify-content: center;
    }
}

@media only screen and (min-width: 550px) and (max-width: 740px) {
    .youtube_band{
        height: 350px;
    }
    .video-container{
        justify-content: center;
    }
}

@media only screen and (min-width: 740px) and (max-width: 920px) {
    .youtube_band{
        height: 350px;
        width: 550px;
    }

    .video-container{
        justify-content: center;
    }
}
/* Large Screen Styles */
@media only screen and (min-width: 920px) and (max-width: 1080px) {
    .youtube_band{
        height: 400px;
        width: 800px;
    }
}