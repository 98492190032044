.div_content_admin{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-right : 3px solid #EDEAD2;
 
}
.admin_content {
    flex: 1; /* Utilise tout l'espace restant */
    margin-right: 20px;
    border-left : 2px solid #EDEAD2;
}
.admin_panel {
    margin-top: 30px;  
width: 250px; /* Largeur de la colonne de gauche */
   margin-left: 20px;
}

.h3_admin{
    font-size: 20px;
    margin-left: 20px;
    
}
.btn_admin{
    font-size: 16px;
    background-color: #F6F5F3;
    border: none;
    margin-top: 30px;
    cursor:pointer
}
.btn_admin.clicked {
    color: #e5a558;
}
.tab_display_admin{
    margin-top: 20px;
    padding: 40px;
}
.form_admin{
    margin-top: 20px;
    background-color: white;
    border: 1px solid #ccc;
 
}
.btn_admin_2{
    font-size: 16px;
    background-color: #F6F5F3;
    border: none;
    
    cursor:pointer;
    padding-right: 200px;
}
.btn_admin_2.clicked {
    color: #e5a558;
}
.select_manage{
    font-size: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
   
    color: #555;
    font-weight: bold;
    
   
}
.select_input_manage{
    width: 50%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.3s ease-in-out;
  margin-left: 75px;
  
}
.select_container{
    margin-top: 20px;

}
.select_manage{
  
margin-left: 10px;
    color: #e5a558;
}
