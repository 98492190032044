.project_content{
    margin-top: 100px;
    text-align: center;
    width: 70%;
    margin: 100px auto;
    margin-bottom: 40px ;
    font-size: var(--font-size-title-default-music);
}
.project_img_font{
    height: auto;
    background-color: #F8F7F7;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .project_content{
        margin-top: 0px;
        width: 80%; 
        font-size: var(--font-size-title-mobile-music);
    }
}
/* mini laptop Styles */
@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .project_content{
        font-size: var(--font-size-title-tablet-music);
    }
}