.construction-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Prend toute la hauteur de la fenêtre */
    background-color: white;
    /* Couleur de fond légère */
  }
  
  .construction-content {
    text-align: center;
    background: white;
    padding: 20px;
  
  }
  
  .construction-content h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .construction-content p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .mobile-alert {
    
    display: none;
    text-align: center;
    transform: translate(0%, 50%);
    font-size: 18px;
    background-color: #eadbbc;
margin-top: 40px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1001;
   margin-bottom: 100px;
  
  }
  
  @media (max-width: 768px) {
    .mobile-alert {
    display: block;  
    
      /* Affiche l'alerte sur les écrans plus petits */
    }
  }