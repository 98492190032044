table {
  border-collapse: collapse;
  margin: auto;
}

td {
  border-top: 1px solid black;
  margin-top: 20px;
  padding: 15px;
  font-size: 20px;
 
  /* Assurer que le texte est centré */
}

th {
  font-size: 20px;
  padding: 30px;
  text-align: center;
  /* Assurer que le texte est centré */

}

.cart-item {
  text-align: center;
}


.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
}

.contain_delivery {
  margin-left: 800px;
  text-align: right;
}

.total-column {
  text-align: right;
  font-weight: bold;
  padding-right: 60px;
  border-top: none;

}
.delivery-column {
  text-align: right;
  font-style: italic;
  padding-right: 50px;
  border-top: none;
  font-size: 18px;

}


.input_check {
  cursor: pointer;
  vertical-align: middle;
}

.img_bottle {
  width: 100px;
}

input {
  width: 10px;
}

.table_container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  width: 1050px;
  height: auto;
  background-color: white;
}
.no-border-top{
  border-top: none;
}
.info_supp{
  font-size: 12px;
 
}

@media only screen and (min-width: 320px) and (max-width: 355px) {

  td,
  th {
    font-size: 10px;
    padding: 6px;
  }


  .table_container {
    width: 290px;
    margin-top: 40px;
  }

  .total-column {
    padding-right: 10px;
    font-size: 10px;
    text-align: left;
    font-weight:normal;
  }
  .img_bottle {
    width: 20px;
    /* Réduction supplémentaire de la taille de l'image */
  }
  .delivery-column{
    font-size: 10px;
    padding-right: 20px;
  }
  .info_supp{
    font-size: 8px;
   
  }
  .delivery-column {
    text-align: right;
    font-style: italic;
    border-top: none;
    font-size: 10px;
  
  }
  
}

@media only screen and (min-width: 355px) and (max-width: 381px) {

  td,
  th {
    font-size: 10px;
    padding: 6px;
  }


  .table_container {
    width: 320px;
    margin-top: 40px;
  }

  .total-column {
    padding-right: 10px;
    font-size: 10px;
    text-align: left;
    font-weight:normal;
  }
  .img_bottle {
    width: 30px;
    /* Réduction supplémentaire de la taille de l'image */
  }
  .delivery-column{
    font-size: 10px;
    padding-right: 20px;
  }
  .info_supp{
    font-size: 10px;
   
  }
}

@media only screen and (min-width: 381px) and (max-width: 480px) {
  td,
  th {
    font-size: 12px;
    padding: 8px;
  }


  .table_container {
    margin-top: 40px;
    max-width: 355px;
  }

  .total-column {
    padding-right: 10px;
    font-size: 10px;
    text-align: left;
    font-weight:normal;
  }

  .img_bottle {
    width:40px;
    /* Réduction supplémentaire de la taille de l'image */
  }
  .delivery-column{
    font-size: 10px;
    padding-right: 20px;
  }
  .info_supp{
    font-size: 10px;
   
  }
}

@media only screen and (min-width: 521px) and (max-width: 768px) {
  td,
  th {
    font-size: 15px;
    padding: 8px;
  }

  .table_container {
    max-width: 500px;
    margin-top: 40px;
  }

  .total-column {
    padding-right: 10px;
  }

  .img_bottle {
    width: 50px;
  }
  .delivery-column{
    font-size: 15px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  td,
  th {
    font-size: 18px;
    padding: 8px;
  }

  .table_container {
    max-width: 700px;
  }

  .total-column {
    padding-right: 10px;
    /* Réduction du padding pour s'adapter aux petits écrans */
  }

  .img_bottle {
    width: 50px;
    /* Réduction supplémentaire de la taille de l'image */
  }
}
