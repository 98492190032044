:root{
    --font-size-title-default: 60px ;
    --font-size-subtitle-default:30px;
    --margin-bottom-default:20px;
    --font-size-title-mobile: 25px ;
    --font-size-subtitle-mobile:20px;
    --font-size-title-tablet: 40px ;
    --font-size-subtitle-tablet:30px;
}

.music_title_main {
    font-size: var(--font-size-title-default);
    font-family: sans-serif;
  
    margin-bottom: var(--margin-bottom-default);
  
}
.music_subtitle_main{
    font-size: var(--font-size-subtitle-default);
    margin-bottom: var(--margin-bottom-default);
    margin-top: 20px;
}


/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .music_title_main {
        font-size: var(--font-size-title-mobile);      
    }
    .music_subtitle_main{
        font-size: var(--font-size-subtitle-mobile);
    }
}

@media only screen and (min-width: 768px) {
    .music_title_main {
        font-size: var(--font-size-title-tablet);      
    }
    .music_subtitle_main{
        font-size: var(--font-size-subtitle-tablet);
    }
}

@media only screen and (min-width: 420px) and (max-width: 550px) {
 
}

@media only screen and (min-width: 550px) and (max-width: 740px) {
 
}

@media only screen and (min-width: 740px) and (max-width: 920px) {
  
}
/* Large Screen Styles */
@media only screen and (min-width: 920px) and (max-width: 1080px) {
}