.btn_align {
  margin-left: 20px;
  text-decoration: none;
  margin-right: 40px;
  color: #c18e4f !important;
}

.btn_align_musique {
  margin-left: 20px;
  text-decoration: none;
  margin-right: 40px;
  color: green !important;
}

.btn_nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  text-transform: capitalize;
}

.btn_nav a {
  text-decoration: none;
}

.btn_align:active,
.btn_align:focus,
.btn_align:hover,
.btn_align:visited {
  color: #c18e4f;
}

.btn_align_musique:active,
.btn_align_musique:focus,
.btn_align_musique:hover,
.btn_align_musique:visited {
  color: rgb(42, 227, 82);
}

.btn_home {
  font-size: 24px;
  color: black;
  text-decoration: none;
  margin-right: 20px;
}

.btn_nav_content {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 20px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(112, 85, 85, 0.1);
  font-size: 25px;
  z-index: 1000;
}

.cart-count {
  position: absolute;
  margin-top: -15px;
  right: -5px;
  background-color: #e5a558;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.navbar_toggler {
  display: none;

  background-color: white !important;
  border: white !important;
  font-size: 1.5rem;
  color: black !important;
  cursor: pointer;
  z-index: 1010;
}

.navbar_toggler:hover {
  background-color: white !important;
  box-shadow: none !important;
  color: black !important;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.nav-menu {
  color: black;
  margin-left: 20px;
  text-decoration: none;
}
.navigation_shop {
  width: 2.3rem;
  height: 2rem;
  position: relative;
  margin-top: 10px;
  margin-right: 25px;
  padding: 5px;
  border: none;
  color: #c18e4f;
}

.btn-outline-primary:hover{
  background-color: transparent !important ;
  border-color: #c18e4f !important; /* Empêche le changement de couleur de bordure */
  color: #c18e4f !important; /* Empêche le changement de couleur de texte */
}

.body-overflow {
  overflow: hidden;
}


@media only screen and (max-width: 480px) {

  .navbar_toggler {
    display: block;
  }

  .btn_home {
    font-size: 20px;
    margin-top: 0px;
    margin-left: -5px;
  }

  .btn_nav_content {
    position: relative;
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .nav-menu {
    flex-direction: column !important;
    padding-top: 100px;
    width: 80%;
    height: 100vh;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1001;
    /* S'assurer que le menu est au-dessus de l'overlay */
  }

  .nav-menu.open {
    transform: translateX(0);
  }

  .btn_align,
  .btn_align_musique,
  .cart-btn {

    font-size: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .cart-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }

  .cart-count {
    width: 15px;
    height: 15px;

    font-size: 10px;
  }

  .navigation_shop {
    margin-left: 20px;
    width: 35px;
  }

}

@media only screen and (min-width: 480px) and (max-width: 768px) {



  .btn_home {
    font-size: 20px !important;
    margin-top: 0px;
  }

  .btn_nav_content {
    position: relative;
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .navbar_toggler {
    display: block;
  }

  .nav-menu {
    flex-direction: column !important;
    padding-top: 100px;
    width: 80%;
    height: 100vh;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1001;
    /* S'assurer que le menu est au-dessus de l'overlay */
  }

  .nav-menu.open {
    transform: translateX(0);
  }

  .btn_align,
  .btn_align_musique,
  .cart-btn {
    font-size: 18px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .cart-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }

  .cart-count {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  .navigation_shop {
    margin-left: 20px;

  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .navbar_toggler {
    display: block;
  }

  .btn_home {
    font-size: 20px !important;
    margin-top: 0px;
  }

  .btn_nav_content {
    position: relative;
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .nav-menu {
    flex-direction: column !important;
    padding-top: 100px;
    width: 80%;
    height: 100vh;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1001;
    /* S'assurer que le menu est au-dessus de l'overlay */
  }

  .nav-menu.open {
    transform: translateX(0);
  }

  .btn_align,
  .btn_align_musique,
  .cart-btn {
    font-size: 25px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .cart-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .cart-count {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  .navigation_shop {
    margin-left: 20px;
    width: 45px;
  }
}