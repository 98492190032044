.bottle {
    width: 250px;
    border-right: 2px solid #EDEAD2;
    padding-right: 30px;
}

.wine-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 50px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    width: 950px;
    height: auto
}

.btn_informations {
    background-color: white;
    border: none;
    color: #e5a558;
    font-size: 18px;
    margin-left: -6px;
}

.btn_informations:hover{
    background-color: #d69545;
}
.btn-primary:focus{
    background-color: white;
    color: #e5a558;

}

.btn_popup {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
}

.wine-details {
    vertical-align: middle;
    margin-left: 50px;
    width: 500px;
}

.title_size {
    font-size: 25px;
    margin-bottom: 2px;
    font-weight: bold;
}

.quantity-control {
    display: flex;
    align-items: center;
}

.select_quantity {
    margin-right: 18px;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-size: 1rem;
    color: #333;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.notification {
    position: fixed;
    top: 0;
    right: -350px;
    height: 100%;
    width: 350px;
    background-color: #fff;
    padding: 20px;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    transition: right 0.5s ease-in-out;

}

.notification.show {
    right: 0;
}

.notification button {
    background-color: #F6F5F2;
    border: none;
    color: #333;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 4px 2px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
}

.notification button:hover {
    background-color: #f1f1f1;
}

.addBasket_notif {
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.recapOrder_notif {
    color: #555;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.bottle_notif {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.btn_add {
    border: none;
    background-color: white;
 
}

.item_notif {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    /* Ligne de séparation */
}

.text_notif {
    font-size: 18px;
    margin: 0;
}

.cart_item_notif {
    flex: 1;
    
}
*,
*::before,
*::after {
  box-sizing: content-box !important;
}

.price_notif {
    font-size: 14px;
    color: #333;
    margin-top: 5px;
}

.notification .close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
    box-shadow: none;

}

.wine-list-description1 {
    list-style: none;
    padding-left: 0;
    font-size: 20px;
    font-style: italic;
   
    margin-bottom: 20px;
}

.wine-list-description3 {
    padding-left: 0;
    font-size: 18px;
    margin-top: -20px;
    margin-bottom: 25px;
}

.no-scroll {
    overflow: hidden;
}

.bg-wine {
    background: url('../assets/wine_glass.png') no-repeat;
    background-size: 20px;
}

.bg-grapes {
    background: url('../assets/grapes.png') no-repeat;
    background-size: 20px;
}

.bg-bottle {
    background: url('../assets/wine_bottle.png') no-repeat;
    background-size: 20px;
}

.bg-barrel {
    background: url('../assets/barrel.png') no-repeat;
    background-size: 20px;
}

.bg-parcelle {
    background: url('../assets/wine_parcelle.png') no-repeat;
    background-size: 20px;
}

.wine-list-description2 {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    margin-left: 0px;
    padding-inline-start: 0px;

}

.wine-list-description2 li {
    margin-bottom: 15px;
    /* Ajustez cette valeur selon vos besoins */
    padding-inline-start: 40px;
}

.wine-list-description::marker {
    content: '';
    /* Supprime la puce standard */
}
.notification-buttons{
    display: flex;
flex-direction: column;
}
.bottle_notif{
    width: 80px;
    margin-left: 20px;
}


@media only screen and (max-width: 320px) {

    .wine-container {
        flex-direction: column;
        display: flex;
        width: 250px;   
        align-items: center;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .wine-details {
        width: auto;
        margin-left: 0px; 
    }

    .bottle {
        width: 100px;
        border-bottom: 2px solid #EDEAD2;
        padding-bottom: 10px;
        border-right: none;
        padding-right: 0px;
    }

    .title_size {
        font-size: 14px;
        margin-left: 40px;
    }

    .wine-list-description1 {
        font-size: 12px;
        background-size: 6px;
        text-align: left;
        margin-bottom: 10px;
        margin-left: 40px;
    }


    .wine-list-description2 li {
    
        background-size: 15px;
    }

    .wine-list-description3 {
      
        background-size: 15px;
        font-size: 12px;

    }

    .btn_informations {
        font-size: 12px;
        margin-top: 10px;
        width: auto;

    }

    .quantity-control {
        margin-top: 15px;
    }

    .select_quantity {
        font-size: 12px;
        margin-right: 12px;
    }

    .btn_popup {
      width: auto;
    margin-top: -10px;
    }

    .notification {
        position: fixed;
        width: 200px;
        left: 70px;
    }

    .text_notif {
        font-size: 15px;
        margin: 0;
    }
    .addBasket_notif{
        font-size: 18px;
    }
    .notification .close_btn {
        position: absolute;
        top: 0px;
        left: 180px;
        right: 0px;
        font-size: 20px;
    }
    .bottle_notif{
        width: 50px;
    }

}

@media only screen and (min-width: 321px)  and (max-width: 425px) {
    .notification {
        position: fixed;
        left: 80px;
    }
}
@media only screen and (min-width: 425px)  and (max-width: 450px) {
    .notification {
        position: fixed;
        left: 130px;
    }
}
@media only screen and (min-width: 450px)  and (max-width: 475px){
    .notification {
        position: fixed;
        left: 155px;
    }
}
@media only screen and (min-width: 475px) and (max-width: 480px) {
    .notification {
        position: fixed;
        left: 180px;
    }
}


@media only screen and (min-width: 321px) and (max-width: 480px) {

    .wine-container {
        flex-direction: column;
        display: flex;
        width: 250px;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .wine-details {
        width: auto;   
        margin-left: 0px;   
    }

    .bottle {
        width: 100px;
        border-bottom: 2px solid #EDEAD2;
        padding-bottom: 10px;
        border-right: none;
        padding-right: 0px;
    }

    .title_size {
        font-size: 15px;
        margin-left: 40px;
    }

    .wine-list-description1 {
        font-size: 15px;
        background-size: 6px;
        text-align: left;
        margin-left: 40px;  
    }


    .wine-list-description2 li {
        margin-top: 10px;
        background-size: 15px;
        margin-bottom: 10px;
    }

    .wine-list-description3 {
        background-size: 15px;
        font-size: 12px;
    }

    .btn_informations {
        font-size: 12px;
        margin-top: 10px;
        width: auto;
    }

    .quantity-control {
        margin-top: 15px;
    }

    .select_quantity {
        font-size: 12px;
        margin-right: 10px;
    }

    .btn_popup {
        margin-top: -10px;
        width: auto;
    }
    .notification {
        width: 250px;
    }
  
}

@media only screen and (min-width: 480px) and (max-width: 768px) {

    .wine-container {
        flex-direction: column;
        display: flex;
        width: 400px;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .wine-details {
        width: auto;   
        margin-left: 0px;   
    }

    .bottle {
        width: 180px;
        border-bottom: 2px solid #EDEAD2;
        padding-bottom: 10px;
        border-right: none;
        padding-right: 0px;
    }

    .title_size {
        font-size: 20px;
        margin-left: 40px;
    }

    .wine-list-description1 {
        font-size: 15px;
        background-size: 6px;
        text-align: left;
        margin-left: 40px;

    }


    .wine-list-description2 li {
        background-size: 15px;
        margin-top: 10px;
        background-size: 15px;
        margin-bottom: 10px;
    }

    .wine-list-description3 {
        background-size: 15px;
        font-size: 15px;

    }

    .btn_informations {
        font-size: 15px;
        margin-top: 10px;
        width: auto;
    }

    .quantity-control {
        margin-top: 15px;
    }

    .select_quantity {
        font-size: 12px;
        margin-right: 10px;
    }

    .btn_popup {
 
        width: auto;
        margin-top: -10px;
    }
   

}