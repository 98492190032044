
.presentation_container_1 {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
}

.title_color{
    color: #C2A770;
}
.presentation_container_2 {
    margin-top: -80px;
    height: 60vh;
    background-image: url(../assets/Cornemuse.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.5;
}

.soundcloud_presentation {
    margin: 0 auto;
    text-align: center;
    display: flex;
    margin-top: -40px;
    position: relative;
    z-index: 1000;
    width: 60%;
}

.about_container {
    width: 70%;
    margin: 0 auto;
}

.about_content>* {
    margin-top: 20px;
}

.about_text {
    font-size: var(--font-size-title-default-music);
    text-align: left;
}

.about_content .youtube_band {
    margin-top: 20px;
}

.about_container_2 {
    height: 60vh;
    background-image: url(../assets/Cornemuse2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.5;
    width: auto;
    position: relative;
    z-index: 0;
}

.prestation_container_size {
    height: 100vh;
    background-image: url(../assets/bretagne.jpg);
    background-size: cover;
}

.prestation_title {
    text-align: center;
    padding-top: 40px;
    color: white ;
}

.prestations_contain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    margin-left: 10px;
    margin-right: 10px;
    transform: perspective(1px) translateY(50%);
   
}

.prestations_content {
    padding-left: 30px;
    padding-top: 30px;
    width: 300px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.1); /* Fond transparent */
    color: white; /* Texte en blanc */
    border: 2px solid white; /* Bordure blanche autour */
    border-radius: 5%; /* Bords arrondis */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; /* Effet d'ombre */
}

.prestation_event_title {
    font-size: 20px;

}

.prestion_event_description {
    font-size: var(--font-size-title-default-music);
    margin-top: 20px;
    margin-right: 20px;
}

.prestation_event_button {
    background-color: rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    border-width: 0;
  
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    padding: 10px 12px;
    text-align: center;
    margin-bottom: 20px;
}

.enseignements_container {
    text-align: center;
    width: 70%;
    margin: 0 auto;

}

.section_enseignement {
    background-color: #F8F7F7;
    padding: 20px;
}
.enseignements_text{
    font-size: var(--font-size-title-default-music);
}
.img_enseignement{
    width: 50%;
}

.composition_soundCloud {
    margin-top: 20px;
    width: 500px;
}

.composition_soundclound_contain {
    display: flex;
    justify-content: space-between
}

.composition_container {
    width: 70%;
    margin: 0 auto;
}
.composition_text{
    font-size: var(--font-size-title-default-music);
}
.section {
    padding-top: 20px;
    padding-bottom: 20px;
}


/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .img_profil_home {
        width: 80%;
    }

    .presentation_container_2 {
        height: 30vh;
    }

    .presentation_container_1 {
        flex-direction: column;
    }

    .soundcloud_presentation {
        width: 100%;
        height: 120px;
    }

    .about_container {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .about_text {
        font-size: var(--font-size-title-mobile-music);
        padding-right: 20px;
    }

    .about_container_2 {
        height: 23vh;
    }

    .prestations_contain {
        flex-direction: column;
        transform: perspective(1px) translateY(0%);
    }

    .prestations_content {     
        margin-bottom: 40px;
       width: auto;
    }
  
    .prestation_container_size {
        height: auto;
        background-image: none;
        background-size: auto;
        
    }
    .prestation_title {
        text-align: center;
        padding-top: 0px;
        color: #C2A770 ;
    }
    .prestation_event_title{
        color: #C2A770 ;
    }
    .prestion_event_description{
        font-size: var(--font-size-title-mobile-music);
        color: black;
    }

    .enseignements_container {
        text-align: left;
        width: 100%;
    }
    .enseignements_text, .composition_text{
        font-size: var(--font-size-title-mobile-music);
    }

    .composition_soundCloud {
        height: 20px;
        width: auto;
        margin-bottom: 20px;
    }

    .composition_soundclound_contain {
        flex-direction: column;
    }

    .composition_container {
        width: auto;
        padding-left: 20px;
    }

}

@media only screen and (min-width: 420px) and (max-width: 550px) {
    .about_container_2 {
        height: 30vh;
    }
}

@media only screen and (min-width: 550px) and (max-width: 740px) {
    .about_container_2 {
        height: 40vh;
    }
}
/* mini laptop Styles */
@media only screen and (min-width: 768px) and (max-width: 1400px) {
    
    .presentation_container_2 {
        height: 40vh;
    }
    .prestation_container_size {
        height: auto;
        background-image: none;

    
    }
    .about_text {
        padding-right: 20px;
    }

    .about_container_2 {
        height: 50vh;
    }

    .prestations_contain {
        flex-direction: column;
        transform: perspective(1px) translateY(0%);
    }

    .prestations_content {
        margin-bottom: 40px;
        width: auto;
    }
    .prestation_container_size {
        height: auto;
    
    }
    .prestation_title {
        text-align: center;
        padding-top: 0px;
        color: #C2A770 ;
    }
    .prestation_event_title{
        color: #C2A770 ;
    }
    .prestion_event_description{
        color: black;
    }
    .enseignements_container{
        width: auto;
    }

    .composition_soundCloud {
        height: 20px;
        width: auto;
        margin-bottom: 20px;
    }

    .composition_soundclound_contain {
        flex-direction: column;
    }
    

}
/* mini laptop Styles */
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
.prestation_event_title{
    font-size: 25px;
}
.prestion_event_description{
    font-size: 18px;
}
.enseignements_text ,.composition_text, .about_text{
    font-size: 18px;
}
.prestation_container_size {
    height: auto;
}
.prestations_contain {
    transform: perspective(1px) translateY(0%);
}
.prestation_title {
    text-align: center;
    padding-top: 0px;
    color: #C2A770 ;
}
.prestation_event_title{
    color: #C2A770 ;
}
.prestion_event_description{
  
    color: black;
}
}