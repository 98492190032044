.vignoble_content{
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vignoble_part1_content{
  display: flex;
 align-items: center;
 gap: 20px;
}
.vignoble_title{
  flex: 0 0 auto; /* Le titre prend seulement l'espace nécessaire */
  margin-right: 20px; /* Ajouter un espacement entre le titre et le texte */
  color: #e5a558;
}
.vignoble_paragraph {
  flex: 1; /* Le paragraphe prend le reste de l'espace disponible */
}
.vignoble_img{
 display: flex;
 justify-content: center;
 width: 100%;

}
.vignoble_part2_content{
  margin-top: 30px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
@media only screen and (max-width: 720px) {
  .vignoble_part1_content{
    display: block;
   
  }
  .vignoble_title{
    text-align: left;
    margin-right: 0px;
  }
  .vignoble_paragraph{
    text-align: left;

  }


}


@media only screen and (min-width: 720px) and (max-width: 1024px) {
  .vignoble_img{
    width: 100%;
    margin-bottom: 0px;
  }
}