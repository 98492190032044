.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #f9f9f9, #e0e0e0);
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

.intro {
    margin-bottom: 40px;
}

.intro h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: #333;
    font-weight: 700;
}

.intro p {
    font-size: 1.2rem;
    color: #555;
    max-width: 700px;
    margin: 0 auto;
    line-height: 1.6;
}

.choices {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
}

.choice {
    flex: 1;
    margin: 0 20px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 15px;
    transition: transform 0.4s ease, box-shadow 0.4s ease, filter 0.3s ease;
}

.choice:hover {
    transform: scale(1.1);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    filter: brightness(1.1);
}

.choice .overlay_erwan {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
    transition: opacity 0.4s ease;
}

.choice.music {
    background-image: url('musique.jpg');
    background-size: cover;
    background-position: center;
    height: 400px;
}

.choice.wine {
    background-image: url('vins.jpg');
    background-size: cover;
    background-position: center;
    height: 400px;
}

.overlay_erwan {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.choice:hover .overlay_erwan {
    opacity: 1;
}

/* Responsive Design */
@media (max-width: 1000px) {
    .choices {
        flex-direction: column;
        align-items: center;
    }

    .choice {
        margin: 20px 0;
        width: 80%;
        height: auto; /* Reset height */
        border: 1px solid #ddd; /* Optional: Add border to differentiate choices */
    }

    .choice.music::before, .choice.wine::before {
        content: '';
    }

    .overlay_erwan {
        background:#aaa8a8 ; /* Remove overlay background */
      padding-top: 10px;
      padding-bottom: 10px;
        text-shadow: none; /* Remove text shadow */
        opacity: 1; /* Make text always visible */
        position: relative; /* Reset position */
        top: auto;
        left: auto;
    }
   
    .homepage {
        height: auto;
        padding: 10px;
     
    }
}
