.btn_filter{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
 
}
.btn_millesime{
    font-size: 20px;
    padding: 10px;
    background-color: white;
    border-radius: 3px;
    border-color: #F6F5F2;
    border-width: 2px;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
   
   
}
.btn_millesime_selected{
    font-size: 20px;
    padding: 10px;
    border-color: #e5a558;
    background-color: white;
    border-radius: 3px;
    border-width: 2px;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
} 
.Image_size{
    margin: 5px;
    width: 50px;
}
.adress{
    text-align: center;
    margin-top: 10px;
}
@media only screen and (max-width: 480px) {
 
    .btn_millesime{
        font-size: 10px;
    
    }
    .btn_millesime_selected{
        font-size: 10px;
    } 
    .btn_filter{
        margin-top: -10px;
    }
    .Image_size{
       
        margin: 0px;
        width: 30px;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
  
    .btn_millesime{
        font-size: 15px;
    
    }
    .btn_millesime_selected{
        font-size: 15px;
    } 
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    
  }