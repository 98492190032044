@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700&display=swap');

:root{
  --font-size-title-tablet-music: 20px ;
  --font-size-title-default-music: 18px ;
  --font-size-title-mobile-music: 16px ; 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Maven Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
}

.modal-content button {
  margin-top: 10px;
}