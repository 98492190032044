input {
    width: 30%;
    /* margin-bottom: 15px; */
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 12px;
}

.lbl_space_facturationDetails{
    display: flex;
    justify-content:center
    
}

.field {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
  
}

.input_display {
    width: 494px; /* Adjust as needed */
}
.input_size {
    width: 1010px; /* Adjust as needed */
}

.label_facturationDetails {
    font-size: 18px;
    width: 100%;
    margin-top: 8px;
    text-align: left;
    color: #555;
    font-weight: bold;
    margin-left: 11px;
}

.title_position{
    text-align: center;
}
.div_coordonnee{
    display: flex;
    justify-content:center;
    font-size: 18px;
   
}
.btn_submit{
    display: flex;
    justify-content:flex-start;
    margin-left: 220px;
    margin-top: 10px;
}
.popup_autorisation_btn{
    background-color: #F6F5F2;
    border: none; 
    color: #333;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
    margin-bottom: 20px;
    margin-left: 20px;
}
.popup_autorisation_content1{
    text-align: center;
}
.popup_autorisation{
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    position: relative;
}
.popup_autorisation_overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fond noir transparent */
    z-index: 1000;
    box-sizing: border-box;
}
.popup_autorisation_title{
    font-size: 25px;
}


@media only screen and (max-width: 480px) {
    input {
        width: 30%;
        padding: 8px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .lbl_space_facturationDetails{
        display: flex;
        justify-content:center
        
    }

    .input_display {
        width: 132px; /* Adjust as needed */
    }
    .input_size {
        width: 290px; /* Adjust as needed */
    }
    
    .label_facturationDetails {
        font-size: 12px;
    }
    
    .title_position{
        text-align: center;
    }
    .div_coordonnee{
        font-size: 12px;
    }

    .popup_autorisation_btn{
        font-size: 12px;
        padding: 10px;
    }
  
    .popup_autorisation{
        width: 250px;
    }
    .popup_autorisation_title{
        font-size: 15px;
    }
    .popup-subtitle{
        font-size: 12px;
    }
    .popup_autorisation_overlay{
        justify-content: center;
    }
  }
  
  @media only screen and (min-width: 481px) and (max-width: 768px) {
  
    input {
        width: 50%;
        padding: 8px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 12px;
    }
    
 
    .input_display {
        width: 190px; /* Adjust as needed */
    }
    .input_size {
        width: 410px; /* Adjust as needed */
    }
    
    .label_facturationDetails {
        font-size: 12px;
    }
    
    .title_position{
        text-align: center;
    }
    .div_coordonnee{
        font-size: 12px;
    }
    .popup_autorisation_btn{
        font-size: 15px;
        padding: 10px;
    }
  
    .popup_autorisation{
        width: 350px;
    }
    .popup_autorisation_title{
        font-size: 20px;
    }
    .popup-subtitle{
        font-size: 12px;
    }
    .popup_autorisation_overlay{
        justify-content: center;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .input_display {
        width: 400px; /* Adjust as needed */
    }
    .input_size {
        width: 830px; /* Adjust as needed */
    }
  }