:root{
    --font-size-default:20px
}

.btn_navigation{
    background-color: black; 
    color: white;
    text-align: center;
    font-size: var(--font-size-default);
    text-decoration: none;
    border: none;
    padding: 10px 12px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);;
    cursor: pointer;
}
.btn_navigation:hover{
    color: #C2A770;
}
.scroll-to-top {
    position: fixed;
    bottom: 250px;
    right: 20px;
    background-image: linear-gradient(to right bottom, #eadbbc, #e0cea9, #d6c195, #ccb483, #c2a770);
    color:white;
    width: 40px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    border: none;
    z-index: 1000;
  }
  
.scroll-to-top:active {
    transform: scale(0.9);
  }

/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .scroll-to-top {
        display: none;
      }
  }