.condition_content{
   padding-top: 80px;
   padding-left: 20px;
   padding-right: 20px;

}
.condition_header{
    background-color: #8B0000; /* Couleur de fond du header */
    color: #fff; /* Couleur du texte dans le header */
    padding: 20px;
    text-align: center; /* Centrage du texte */
    margin-bottom: 20px;
}
.condition_champ{
    list-style-type: none; /* Retirer les puces des listes */
    display: flex; /* Disposition en ligne */
    justify-content: center; /* Centrage des éléments */
    gap: 20px; /* Espacement entre les éléments */
}
.condition_a{
    color: #fff; /* Couleur des liens dans le header */
    text-decoration: none; /* Retirer le soulignement */
    font-weight: bold; /* Gras pour les liens */
}
.condition_a:hover{
    color: white;
    text-decoration: underline; /* Soulignement au survol */
}
.condition_section {
    margin-bottom: 20px; /* Marge sous chaque section */
}
.condition_point{
    font-weight: bold;
}

/* Smartphones (portrait) */
@media (max-width: 599px) {
    .condition_content {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .condition_header {
        padding: 15px;
    }

    .condition_champ {
        flex-direction: column; /* Liste verticale sur les petits écrans */
        align-items: center; /* Centrer les éléments */
    }

    .condition_champ li {
        margin-bottom: 10px; /* Marge entre les éléments de la liste */
    }
}

/* Tablettes (portrait) */
@media (min-width: 600px) and (max-width: 899px) {
    .condition_content {
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .condition_header {
        padding: 18px;
    }

    .condition_champ {
        flex-direction: row; /* Liste horizontale sur les écrans de taille moyenne */
        gap: 15px; /* Réduction de l'espacement entre les éléments */
    }
}

/* Écrans plus larges */
@media (min-width: 900px) {
    .condition_content {
     
        padding-left: 20px;
        padding-right: 20px;
    }

    .condition_header {
        padding: 20px;
    }

    .condition_champ {
        flex-direction: row; /* Liste horizontale par défaut */
        gap: 20px; /* Espacement entre les éléments */
    }
}