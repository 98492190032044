.content_music {
    background-image: linear-gradient(to right, #eadbbc, #e0cea9, #d6c195, #ccb483, #c2a770);}

ul {
    list-style-type: none;
}

.top {
    display: flex;
    justify-content: space-between;
}

.column h4 {
    font-size: 25px;
}
.column{
    margin-top: 20px;
    font-size: 20px;
 
}

.social_media {
    padding: 25px 50px;
}

a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

a.social:hover {
    transform: translateY(-2px);
}

a.youtube {
    color: #eb3223;
}

a.facebook {
    color: #4968ad;
}

a.insta {
    color: purple;
}
.qr-code_size{
    width: 32px;
  
}

@media only screen and (max-width: 480px) {
    .top {
        flex-direction: column;
        align-items: center;
    }

    .column {
        flex: none;
        width: 100%;
      margin-top: 10px;
    }
    li{
        font-size: 12px;
    }
   .social_media{
    padding: 0px;
    margin-left: -15px;
   
   }
   
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .top {
        flex-direction: column;
        align-items: center;
    }

    .column {
        flex: none;
        width: 100%;
        margin-top: 10px;
    }
    li{
        font-size: 15px;
    }
   .social_media{
    padding: 0px;
    margin-left: -15px;
   
   }
}
