
.btn_submit{
    margin-top: 40px;
}

.lbl_space{
    display: flex;
    margin-top: 10px;
    margin-left: 20px;
}
.label_manage{
    font-size: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 20%;
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: left;
    color: #555;
    font-weight: bold;
    
}
.textarea_manage{
    font-size: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: -15px;
    width: 20%;
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: left;
    color: #555;
    font-weight: bold;
}
.input_space{
    display: block;
    width: 50%;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.textarea_space{
    display: block;
    width: 50%;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: -15px;
    padding: 12px 20px;
    resize: none;
    box-sizing: border-box; /* S'assure que le padding est inclus dans la largeur */
}
.btn_container{
    display:flex;
    justify-content: flex-end;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.btn_manage {
   
    background-color: #F6F5F2;
    border: none; 
    color: #333;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
}
