.btn_content {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 230px;
}

.btn_contain_submit {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 230px;
}

.btn_next {
    background-color: #F6F5F2;
    border: none; 
    color: #333;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
}

.btn_next:hover {
    transform: translateY(-2px);
    background-color: #e5a558 ;
}

.title_style {
    margin-top: 70px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 50px; 
    font-family: 'Lucida Grande'
}
.title_facturationProcess{
    padding-top: 100px;
    padding-bottom: 30px;
    text-align: center;
    font-size: 50px; 
    font-family: 'Lucida Grande'
}
.subtitle_facturationProcess{
    margin-top: -20px;
    font-size: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.facturationProcess_style{
    text-align: center;
  }

.popup_overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fond noir transparent */
    z-index: 1000;
    box-sizing: border-box;
}


.popup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 800px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
}

.popup-title {
    margin-top: 50px;
    
    font-size: 24px;
    
}

.popup_content2{
    margin-left: 30px;
}

.popup-bottle {
    width: 200px;
}

.popup_content1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: 272px;
}


.popup-millesime {
    color: #e5a558;
    font-size: 18px;
}

.popup-accord {
    font-size: 18px;
    line-height: 1, 5;
}

.popup-accord .line {
    display: block;
}
.popup_container{
    width: auto; /* Définissez la largeur de votre popup */
    height: 180px; /* Définissez la hauteur de votre popup */
    overflow-y: scroll; /* Active le défilement vertical avec la souris */ 
    font-size: 18px;
    line-height: 1, 5;
    list-style: none;
    padding-left: 0;
}
.popup_container li {
  
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 20px;

}

.bg-wine-popup {
    background: url('../assets/wine_glass.png') no-repeat;
    background-size: 20px;
  }

  .bg-grapes-popup {
    background: url('../assets/grapes.png') no-repeat ; 
    background-size: 20px;  
}
  .bg-bottle-popup {
    background: url('../assets/wine_bottle.png') no-repeat; 
    background-size: 20px; 
}
  .bg-barrel-popup {
    background: url('../assets/barrel.png') no-repeat; 
    background-size: 20px; 
}
.bg-parcelle-popup{
    background: url('../assets/wine_parcelle.png') no-repeat; 
    background-size: 20px; 
}
.popup-container::-webkit-scrollbar {
    width: 5px; /* Largeur de la barre de défilement */
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    .title_style {
        margin-top: 15px;
        font-size: 30px; 
        margin-bottom: 0px;
     }
     .title_facturationProcess{
        padding-top: 10px;
        font-size: 25px;
        padding-bottom: 0px;
        
    }
    .subtitle_facturationProcess{
        padding-top: 20px;
        font-size: 12px;
        margin-bottom: 20px;
    }
    .btn_next {
        font-size: 12px;
    }
    .btn_content {
        margin-right: 20px;
    }
    .btn_contain_submit {
        margin-right: 30px;
    }
    .popup-bottle{
        width: 120px;
    }
    .popup_overlay {
        display: flex;
        justify-content:flex-start;
    }
    .close{
        top: 0px
    }

    .popup-millesime{
        font-size: 15px;
    }
    .bg-wine-popup ,
    .bg-grapes-popup,
    .bg-barrel-popup,
    .bg-bottle-popup,
    .bg-parcelle-popup {
       background-image: none;
    }
    .popup_content1{
        padding: 0px;
    }
    .popup_container li {
        padding-left: 0px;
       
    }
  }

  @media only screen and (min-width: 375px) and (max-width: 435px) {
     .title_style {
        margin-top: 15px;
        font-size: 30px; 
        margin-bottom: 0px;
     }
  
     .title_facturationProcess{
        padding-top: 10px;
        padding-bottom: 0;
        font-size: 28px;
        margin-bottom: 10px;
    }
    .subtitle_facturationProcess{
        padding-top: 0;
        font-size: 15px;
        width: 300px;
       margin: 0 auto;
        margin-bottom: 30px;
    }
    .btn_next {
        font-size: 15px;
    }
    .btn_content {
        margin-right: 20px;
    }
    .btn_contain_submit { 
        margin-right: 30px;
    }
    .close{
        top:0px
    }


    .popup-bottle{
        width: 150px;
    }
 
    .popup-millesime{
        font-size: 15px;
    }
 
    .popup_content1{
        padding: 0px;
        margin: auto 0;
    }
    .popup_container li {
        padding-left: 30px;
       
    }
    .popup_overlay {
        display: flex;
        justify-content:flex-start;
      
    }
  }
  @media only screen and (min-width: 435px) and (max-width: 769px) {
    .title_style {
       margin-top: 10px;

       font-size: 30px; 
       margin-bottom: 0px;
    }
 
    .title_facturationProcess{
       padding-top: 20px;
       padding-bottom: 0;
       font-size: 30px;
   }
   .subtitle_facturationProcess{
       padding-top: 20px;
       font-size: 15px;
       margin-bottom: 20px;
   }
   .btn_next {
       font-size: 15px;
   }
   .btn_content {
       margin-right: 20px;
   }
   .btn_contain_submit {
    
       margin-right: 30px;
   }
   .popup{
       width: 73%
    
   }

   .popup-bottle{
       width: 150px;
   }

   .popup-millesime{
       font-size: 15px;
   }

   .popup_content1{
       padding: 0px;
       margin: auto 0;
   }
   .popup_container li {
       padding-left: 30px;
      
   }
   
 }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .title_style {
   
       margin-top: 20px;
        font-size: 40px; 
        margin-bottom: 0px;
     }
  
     .title_facturationProcess{
        padding-top: 10px;
        font-size: 40px;
    }
    .btn_next {
        font-size: 20px;
    }
    .btn_content {
        margin-right: 20px;
    }
    .btn_contain_submit {
     
        margin-right: 30px;
    }
  }