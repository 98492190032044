/* Conteneur général du slider */
.slider-container {
    width: 100%;
    overflow: hidden; /* Cache les images en dehors du conteneur */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
  }
  
  /* Slider contenant les images */
  .slider {
    display: flex;
    width: 100%;
  }
  
  /* Piste de défilement */
  .slide-track {
    display: flex;
    width: calc(300px * 6); /* Largeur des images fois le nombre d'images */
    animation: scroll 20s linear infinite; /* Animation fluide et infinie */
  }
  
  /* Style des images */
  .slide_image {
    min-width: 350px; /* Taille des images */
    height: 200px;
    margin: 0 20px;
  }
  
  .slide_image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
  }
  
  /* Animation du défilement */
  @keyframes scroll {
    0% {
      transform: translateX(50);
    }
    100% {
      transform: translateX(calc(-300px * 3)); /* Fait défiler les 3 images */
    }
  }
  