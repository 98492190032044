.map_container{
    height: 500px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.project_images{
    display: flex;
    gap:20px;    
}


.map_video{
    width: 600PX;
    padding-right: 20px;

}
.leaflet-popup-content-wrapper{
    width: 640PX;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .map_container{
        height: 300px;
        width: 80%;
    }
    .map_video{
        width: auto;
        padding-right: 20px;
    }
    .leaflet-popup-content-wrapper{
        width: auto;
    }
}
/* mini laptop Styles */
@media only screen and (min-width: 768px) and (max-width: 1400px) {
    
    .map_video{
        width: auto;
        padding-right: 20px;

    }
    .leaflet-popup-content-wrapper{
        width: auto;
    }
    
}
/* mini laptop Styles */
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
.prestation_event_title{
    font-size: 25px;
}
.prestion_event_description{
    font-size: 18px;
}
.enseignements_text ,.composition_text, .about_text{
    font-size: 18px;
}
.prestation_container_size {
    height: auto;
}
.prestations_contain {
    transform: perspective(1px) translateY(0%);
}
}
