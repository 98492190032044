:root {
    --font-size-nav: 20px;
    --color-nav-link: black;
    --margin-left-link-default: 20px;
    --nav-z-index: 1001;
    --toggler-z-index: 1010;
    --transition-nav-menu: all 0.3s ease-in-out;
}

.custom-navbar {
    background-color: white;
   transition: all 0.3s ease;
}

.container {
    width: auto;
}

Nav {
    font-size: var(--font-size-nav);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: var(--nav-z-index);
}


.btn_header {
    color: var(--color-nav-link) !important;
}

.btn_cave {
    color: #C2A770 !important;
}
.navbar-toggler{
    margin-top: 8px;
    background-color: transparent; 
    border: none; 
    font-size: 24px;
    color:#C2A770 !important;
 
 
   
}

.nav-menu {
    display: flex;
    align-items: center; 
    flex-direction: row; 
    margin-left: auto;
    transition: all 0.3s ease;  
    
}


/* Cacher le menu sur les petits écrans par défaut */
.nav-menu-music.open {
    display: flex; 
    flex-direction: column; 
    position: absolute; 
    align-items: center;
    top: 52px;
    left: 0;
    right: 0;  
    background-color: white;
    padding: 5px; 
    
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Styles pour les liens de navigation */
.btn_header, .btn_cave {
    color: #fff;
    margin: 10px; 
    text-decoration: none !important;
}

@media (max-width: 993px) {
    :root {
        --font-size-nav: 18px; 
    }

    .btn_header, .btn_cave {
        margin: 5px 0;
    }
    .nav-menu-music {
        display: none; /* Masqué par défaut */
    }
    .nav-menu.open {
        padding: 10px;
    }
    .fixed-top{
        position: relative;
    }
   
}

@media (max-width: 480px) {
    :root {
        --font-size-nav: 16px;
    }

    .btn_header, .btn_cave {
        margin: 5px 0;
    }
    .nav-menu-music {
        display: none; /* Masqué par défaut */
    }
    .nav-menu.open {
        padding: 10px;
    }
    .fixed-top{
        position: relative;
    }    
}
