.carousel-container {
    width: 100vw;
    height: 50vw;
    overflow: hidden;
    position: relative;
}

.carousel {
    width: 100%;
    height: 100%;
    display: flex;
}

.carousel .slide {
    flex: 0 0 100%;

    /* Chaque image occupe 100% de la largeur */
}

.Home_text_2 {
    font-size: 22px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.carousel .slide img {
    width: 100%;

    object-fit: cover;
}

.carousel .overlay {
    position: absolute;
    width: 80%;
    top: 25%;
    transform: translate(-50%, -50%);
    left: 50%;
    text-align: center;
    color: #FFF;
    background-color: rgba(90, 74, 74, 0.6);
    padding: 1rem;
    /* Ajoutez du padding pour améliorer la lisibilité */
}

.carousel .thumbs-wrapper {
    display: none;
}

.overlay_title {
    font-size: 90px;
    margin-bottom: 10px;
    font-family: 'Lucida Grande'
}

.overlay_text {
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
        /* Taille du texte du paragraphe */
}

.presentation_content1 {
    position: relative;
    top: -0;
    /* Ajustez cette valeur selon vos besoins */
    left: 50%;
    transform: translateX(-50%);
    color: #f7ae55;
    padding: 1rem;
    display: flex;
    width: 100%;
    height: auto;
}

.presentation_content2 {

    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 160px;
}

.Poeme_style {
    font-style: italic;
    text-align: center;
    color: black;
    font-size: 25px;
}

.presentation_erwanTapparel {
    color: black;
    font-size: 25px;
}

.presentation_text1 {
    flex: 1;
    font-size: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
}

.presentation_image2 {
    width: 400px;
    height: auto;
    border: 6px white solid
}

.presentation_text2 {
    text-align: center;
    margin-top: 80px;
    line-height: 1.5;
    font-size: 25px;
}

.presentation_size_all {
    max-height: 600px;
}

.presentation_p1 {
    color: black;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

@media only screen and (max-width: 389px) {

    .carousel-container{
        height: 60vw;
    }

    .carousel .overlay {
        position: absolute;
        top: 45%;
    }

    .overlay_title {
        font-size: 20px;
    }

    .overlay_text {
        font-size: 15px;
    }

    .presentation_text1 {
        font-size: 15px;
    }

    .presentation_content1 {

        width: 90%;
        display: block;
        text-align: center;
    }

    .Poeme_style {
        margin-left: 0px;
        font-size: 12px;
    }

    .Home_text_2 {
        font-size: 13px;
    }
}

@media only screen and (min-width: 389px) and (max-width: 480px) {
    .carousel .overlay {
        position: absolute;
        top: 35%;
    }

    .overlay_title {
        font-size: 30px;
    }

    .overlay_text {
        font-size: 15px;
    }

    .presentation_text1 {
        font-size: 15px;
    }

    .presentation_content1 {

        width: 90%;
        display: block;
        text-align: center;
    }

    .Poeme_style {
        margin-left: 0px;
        font-size: 12px;
    }

    .Home_text_2 {
        font-size: 13px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
    .carousel .overlay {
        position: absolute;
        top: 35%;
    }

    .overlay_title {
        font-size: 40px;
    }

    .overlay_text {
        font-size: 18px;
    }

    .presentation_text1 {
        font-size: 18px;
    }

    .presentation_content1 {
        width: 90%;
        display: block;
        text-align: center;
    }

    .Poeme_style {
        margin-left: 0px;
        font-size: 13px;
    }

    .Home_text_2 {
        font-size: 15px;
    }
}