.image-container {
    margin-top: -15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1000;
}

.responsive-image {
    width: 100%;
    height: auto;
    max-width: 990px;
    /* Ajuste cette valeur selon tes besoins */
}

.link_wines:visited {
    color: #000;
    /* Same as the normal state */
}
.link_wines_qrCode {
   
    transition: background-color 0.3s, transform 0.3s;
    position: absolute;
    top: 3670px;
    cursor: pointer;
    font-size: 35px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    text-decoration: none;
    color: #c9521b;
}
.top_link_qrCode{
    cursor: pointer;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    text-decoration: none;
    color: #c9521b;
    left: 50%;


}
.top_link_qrCode_contain{
    text-align: center;
    display: flex;
    justify-content: center;
    background-image: url("../assets/background.png");
    background-repeat: no-repeat;
    background-position: center; /* Centers the background image */
    align-items: center;
    background-size: 990px;
}

.bottom_link_qrCode_contain{
    cursor: pointer;
    font-size: 35px;
    margin-bottom: 20px;
    background-image: url("../assets/background_bot.png");
    background-repeat: no-repeat;
    background-position: center; /* Centers the background image */
    align-items: center;
    background-size: 990px;
    text-decoration: none;
    color: #c9521b;   
}
.bottom_wines_qrCode{
    text-align: center;
    text-decoration: none;
    color: #c9521b;
    display: flex;
    padding: 10px 20px;
    font-size: 30px;
    justify-content: center; 
}
.btn-primary{
    background-color: transparent !important;
    border-color: transparent  !important;
    color: #c9521b !important;
}

@media only screen and (max-width: 480px) {
    .bottom_link_qrCode_contain, .top_link_qrCode{
        font-size: 18px;
        margin-bottom: 0px;
    }
    .top_link_qrCode{
        margin-top: 0px;
      
    }
    .image-container {
        margin-top: 0px;
}
.bottom_wines_qrCode{
    font-size: 18px;
}
}
  
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    .bottom_link_qrCode_contain, .top_link_qrCode{
        font-size: 20px;
        margin-bottom: 0px;
    }
    .top_link_qrCode{
        margin-top: 0px;
      
    }
    .image-container {
        margin-top: 0px;
}
.bottom_wines_qrCode{
    font-size: 20px;
}
}